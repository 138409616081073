import { action, makeObservable, observable } from "mobx";
import { ChildStore } from "./child.store";
import { RootStore } from "./root.store";

const VALIDATION_API_SUBDOMAIN = "validation-api";
const VALIDATION_API_LOCAL_PORT = "8080";

const VALIDATION_GCS_SUBDOMAIN = "validation-gcs";
const VALIDATION_GCS_LOCAL_PORT = "";

export enum ApiEndpointPath {
  VISION_PROGRAM = "validation_api/vision_program",
  JWT_AUTHENTICATOR = "validation_api/jwt_authenticator",
  ALL_VISION_PROGRAMS = "validation_api/all_vision_programs",
  ALL_UNVALIDATED_VIDEOS = "validation_api/all_unvalidated_videos",
  START_VALIDATION_RUN = "validation_api/start_validation",
  CLONE_VALIDATION = "validation_api/clone_validation",
  COMPLETE_VALIDATION = "validation_api/complete_validation",
  COMPLETE_COUNTLINE_VALIDATION = "validation_api/complete_countline_validation",
  REOPEN_COUNTLINE_VALIDATION = "validation_api/reopen_countline_validation",
  NOTES = "validation_api/notes",
  FETCH_VALIDATION_RUN_CROSSINGS = "validation_api/validation_crossings",
  FETCH_CV_RUN_DETAILS = "validation_api/computer_vision_run_details",
  EDIT_VALIDATION_RUN_CROSSING = "validation_api/crossing",
  DELETE_VALIDATION_RUN = "validation_api/delete_validation_run",
  FETCH_CV_RUN_CROSSINGS = "computer_vision_run_queue/countline_crossings",
  FETCH_TURNING_MOVEMENTS = "computer_vision_run_queue/turning_movements",
  CREATE_CV_RUN = "computer_vision_run_queue/create",
  CREATE_CV_RUN_WITH_CONFIG = "computer_vision_run_queue/create_with_config",
  RESET_CV_RUN_ATTEMPT_COUNTER = "computer_vision_run_queue/reset_attempt_counter",
  CHANGE_CV_RUN_VISIBILITY = "computer_vision_run_queue/update_visibility",
  UPDATE_RESEARCH_GRADE = "validation_api/update_research_grade",
  SET_RETAIN_VIDEO = "validation_api/retain_video"
}

export enum GcsEndpontPath {
  SIGNED_URL = "signed_url",
  LIST_THUMBNAIL_VERSIONS = "list_thumbnail_versions",
  GET_VERSIONED_THUMBNAIL = "video_thumbnail",
}

export class ApiStore extends ChildStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @observable
  jwt = "";

  @action
  init() {}

  @action
  setJwt(jwt: string) {
    this.jwt = jwt;
  }

  async authenticatedFetch(input: RequestInfo | URL, init?: RequestInit, forcedJwt?: string): Promise<Response> {
    const headers = { ...init?.headers, Authorization: `Bearer ${forcedJwt || this.jwt}` };
    return fetch(input, {
      ...init,
      headers,
      mode: "cors",
      credentials: "include",
    });
  }
}

// change REACT_APP_GCS_DEV_SERVER in .env to change GCS dev server
export const getGcsEndpoint = (endpointPath: GcsEndpontPath) => {
  const { hostname } = location;
  if (hostname === "localhost") {
    const devEnv = process.env.REACT_APP_GCS_DEV_SERVER;
    return getDevUrl(VALIDATION_GCS_SUBDOMAIN, endpointPath, VALIDATION_GCS_LOCAL_PORT, devEnv);
  } else {
    return getHostUrl(VALIDATION_GCS_SUBDOMAIN, endpointPath);
  }
};

// change REACT_APP_API_DEV_SERVER in .env to change API dev server
export const getApiEndpoint = (endpointPath: ApiEndpointPath) => {
  const { hostname } = location;
  if (hostname === "localhost") {
    const devEnv = process.env.REACT_APP_API_DEV_SERVER;
    return getDevUrl(VALIDATION_API_SUBDOMAIN, endpointPath, VALIDATION_API_LOCAL_PORT, devEnv);
  } else {
    return getHostUrl(VALIDATION_API_SUBDOMAIN, endpointPath);
  }
};

const getHostUrl = (subdomain: string, endpointPath: string) => {
  const host = window.location.host;
  let env = "";
  if (host.includes("dev") || host.includes("localhost")) {
    env = ".dev";
  } else if (host.includes("staging")) {
    env = ".staging";
  }
  return `https://${subdomain}${env}.vivacitylabs.com/${endpointPath}`;
};

const getDevUrl = (subdomain: string, endpointPath: string, localPort: string, devEnv?: string) => {
  if (devEnv === "local") {
    return `http://localhost:${localPort}/${endpointPath}`;
  }

  let env = ".dev";
  if (devEnv === "staging") {
    env = ".staging";
  } else if (devEnv === "production") {
    env = "";
  }

  return `https://${subdomain}${env}.vivacitylabs.com/${endpointPath}`;
};
