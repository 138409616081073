import { SpeedDial, SpeedDialAction, SpeedDialIcon, SvgIcon } from "@mui/material";
import React, { CSSProperties, FC, useState } from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import RouteIcon from "@mui/icons-material/Route";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PetsOutlinedIcon from "@mui/icons-material/PetsOutlined";
import PolylineIcon from "@mui/icons-material/Polyline";
import MasksIcon from "@mui/icons-material/Masks";
import RoundaboutRightIcon from "@mui/icons-material/RoundaboutRight";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DataObjectIcon from "@mui/icons-material/DataObject";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { observer } from "mobx-react";

import { useStores } from "../../hooks/useStores.hook";
import { AutofpsSelect, CarCrash } from "@mui/icons-material";
import BrushIcon from "@mui/icons-material/Brush";
import { action } from "mobx";
import { ValidationMode } from "../../enums";
import { errString } from "../../stores/utils/errorString";

const speedDialButtonPadding: CSSProperties = { padding: "10px" };

export const PlayerSpeedDial: FC<{ height: number }> = observer(({ height }) => {
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [isGroupedDrawingOpen, setGroupedDrawingOpen] = useState(false);

  const { playerUIStore, urlStore, videoStore } = useStores();
  const { selectedValidationMode, selectedVideo } = urlStore;

  const video = videoStore.getVideo(selectedVideo);
  if (!video) {
    return (<></>);
  }

  const toggleShouldRetain = async () => {
    try {
      await videoStore.setRetainVideo(video!.id, !video!.shouldRetain)
      const message = video.shouldRetain ? "the video is now marked NOT to be retained": "the video is now marked to be retained";
      playerUIStore.setSnackbarText(message)
    } catch (e) {
      console.error("could not set should_retain: " + errString(e))
      playerUIStore.setError("An error occurred while trying to update the `should_retain` status of the video.")
    }
  }

  const actions = [
    {
      icon: (
        <ZoomInIcon
          color={playerUIStore.zoomEnabled ? "primary" : "disabled"}
          style={speedDialButtonPadding}
          onClick={playerUIStore.toggleZoomEnabled}
        />
      ),
      name: `${playerUIStore.zoomEnabled ? "Disable" : "Enable"} Zoom`,
    },
    {
      icon: <KeyboardIcon style={speedDialButtonPadding} onClick={playerUIStore.setKeyboardVisible} />,
      name: "Show Keyboard",
    },
    {
      icon: (
        <AutofpsSelect
          style={speedDialButtonPadding}
          color={playerUIStore.playUntilNextCVCrossingMode ? "primary" : "disabled"}
          onContextMenu={e => {
            e.preventDefault();
            playerUIStore.setIsPlayUntilNextCVCrossingClassesModalOpen(true);
            return false;
          }}
          onClick={action(e => {
            playerUIStore.setPlayUntilNextCVCrossingMode(prev => !prev);
          })}
        />
      ),
      name: `${playerUIStore.playUntilNextCVCrossingMode ? "Disable" : "Enable"} Play until next CV Crossing`,
    },
    {
      icon: (
        <RouteIcon
          color={playerUIStore.showTrackTails ? "primary" : "disabled"}
          style={speedDialButtonPadding}
          onClick={playerUIStore.toggleShowTrackTails}
        />
      ),
      name: `${playerUIStore.showTrackTails ? "Hide" : "Show"} Track Tails`,
      grouped: true,
    },
    {
      icon: (
        <CheckBoxOutlineBlankIcon
          color={playerUIStore.showTrackBoxes ? "primary" : "disabled"}
          style={speedDialButtonPadding}
          onClick={playerUIStore.toggleShowTrackBoxes}
        />
      ),
      name: `${playerUIStore.showTrackBoxes ? "Hide" : "Show"} Track Boxes`,
      grouped: true,
    },
    {
      icon: (
        <PetsOutlinedIcon
          color={playerUIStore.showTrackFootprints ? "primary" : "disabled"}
          style={speedDialButtonPadding}
          onClick={playerUIStore.toggleShowTrackFootprints}
        />
      ),
      name: `${playerUIStore.showTrackBoxes ? "Hide" : "Show"} Track Footprints`,
      grouped: true,
    },
    {
      icon: (
        <PolylineIcon
          color={playerUIStore.showCountlines ? "primary" : "disabled"}
          style={speedDialButtonPadding}
          onClick={playerUIStore.toggleShowCountlines}
        />
      ),
      name: `${playerUIStore.showCountlines ? "Hide" : "Show"} Countlines`,
      grouped: true,
    },
    {
      icon: (
        <CarCrash
          color={playerUIStore.showNearMissZones ? "primary" : "disabled"}
          style={speedDialButtonPadding}
          onClick={playerUIStore.toggleShowNearMissZones}
        />
      ),
      name: `${playerUIStore.showNearMissZones ? "Hide" : "Show"} Near Miss Zones`,
      hidden: selectedValidationMode !== ValidationMode.NearMissValidation,
    },
    {
      icon: (
        <MasksIcon
          color={playerUIStore.showMasks ? "primary" : "disabled"}
          style={speedDialButtonPadding}
          onClick={playerUIStore.toggleShowMasks}
        />
      ),
      name: `${playerUIStore.showMasks ? "Hide" : "Show"} Masks`,
      grouped: true,
    },
    {
      icon: (
        <RoundaboutRightIcon
          color={playerUIStore.showTurningZones ? "primary" : "disabled"}
          style={speedDialButtonPadding}
          onClick={playerUIStore.toggleShowTurningZones}
        />
      ),
      name: `${playerUIStore.showTurningZones ? "Hide" : "Show"} Turning Zones`,
      grouped: true,
    },
    {
      icon: <NoteAddIcon style={speedDialButtonPadding} onClick={() => playerUIStore.setIsNotesModalOpen(true)} />,
      name: `Add validation run notes`,
    },
    {
      icon: (
        <FileDownloadIcon style={speedDialButtonPadding} onClick={() => playerUIStore.setIsResultsModalOpen(true)} />
      ),
      name: `Get validation run results`,
    },
    {
      icon: (
        <DataObjectIcon
          style={speedDialButtonPadding}
          onClick={() => playerUIStore.setIsEditTrackHeadDisplayFuncsModalOpen(true)}
        />
      ),
      name: `Edit TrackHead Display`,
    },
    {
      icon: video.shouldRetain ?
          <LockIcon color="primary" style={speedDialButtonPadding} onClick={toggleShouldRetain} /> :
          <LockOpenIcon style={speedDialButtonPadding} onClick={toggleShouldRetain} /> ,
      name: `Retain video`,
      hidden: selectedValidationMode !== ValidationMode.NearMissValidation || video.triggerReason !== "NEAR_MISS",
    },
    {
      icon: (
        <SaveIcon style={speedDialButtonPadding} onClick={() => playerUIStore.keyboardHandlers.SHOW_SAVE_DIALOG()} />
      ),
      name: "Save",
    },
    {
      icon: <CloseIcon style={speedDialButtonPadding} onClick={() => playerUIStore.keyboardHandlers.EXIT_PLAYER()} />,
      name: "Exit",
    },
  ];

  return (
    <SpeedDial
      ariaLabel={"Player Speed Dial"}
      sx={{ position: "fixed", bottom: 16, right: 16 }}
      open={speedDialOpen}
      direction={height > 800 ? "up" : "left"}
      onClose={(e, reason) => {
        if (reason === "blur" || reason === "mouseLeave") {
          return;
        }
        setSpeedDialOpen(false);
      }}
      onOpen={() => {
        setSpeedDialOpen(true);
      }}
      icon={<SpeedDialIcon />}
    >
      <SpeedDialAction
        key={"Drawing"}
        tooltipTitle={"Drawing"}
        icon={<BrushIcon style={speedDialButtonPadding} onClick={() => setGroupedDrawingOpen(!isGroupedDrawingOpen)} />}
      />
      {isGroupedDrawingOpen && (
        <SpeedDial
          ariaLabel={"Drawing Speed Dial"}
          direction={height > 800 ? "left" : "up"}
          sx={
            height > 800 ? { position: "fixed", bottom: 75, right: 16 } : { position: "fixed", bottom: 16, right: 75 }
          }
          open={isGroupedDrawingOpen}
          onOpen={() => {
            setGroupedDrawingOpen(true);
          }}
          onClose={() => {
            setGroupedDrawingOpen(false);
          }}
          icon={<BrushIcon />}
        >
          {actions
            .filter(a => a.grouped)
            .map(groupedAction => (
              <SpeedDialAction key={groupedAction.name} icon={groupedAction.icon} tooltipTitle={groupedAction.name} />
            ))}
        </SpeedDial>
      )}
      {actions
        .filter(a => !a.hidden)
        .filter(b => !b.grouped)
        .map(a => (
          <SpeedDialAction key={a.name} icon={a.icon} tooltipTitle={a.name} />
        ))}
    </SpeedDial>
  );
});
