export enum TrackHeadDisplayFuncEnum {
    TrackText,
    ShouldDisplay,
    LineWidth,
    LineColour,
    LineAlpha,
    FillColour,
    FillAlpha,
    ShouldDisplayFootprint,
    FootprintLineWidth,
    FootprintLineColour,
    FootprintLineAlpha,
    FootprintFillColour,
    FootprintFillAlpha,
    TextSize,
    TextPosition,
    TextColour,
    TextAlpha,
}
