import { Video } from "../video.interface";
import {
  ComputerVisionRunProgressUpdateHydration,
  CountlineValidationRunHydration,
  CVRunCountlineCrossingHydration,
  CVRunCountlineCrossingsCountlineHydration,
  CVRunHydration,
  CVRunTurningMovementsHydration,
  CVRunTurningMovementsStartZoneHydration,
  ValidationCrossingHydration,
  ValidationCrossingsHydration,
  ValidationRunHydration,
  VideoHydration,
  ZoneHydration,
} from "./index";
import {
  CountlineID,
  CVRunID,
  CVRunIDStr,
  FrameNumber,
  FrameNumberStr,
  TrackID,
  TurningMovementEndZone,
  TurningMovementStartZone,
  TurningMovementStartZoneStr,
  ValidationRunID,
  VideoID,
  VPID,
} from "../../domain";
import {
  ComputerVisionRun,
  ComputerVisionRunCountlineCrossings,
  ComputerVisionRunTurningMovements,
} from "../computerVisionRun.interface";
import { ValidationRun } from "../validationRun.interface";
import { CountlineValidationRun } from "../countlineValidationRun.interface";
import { CVRunCountlineCrossing } from "../cvRunCountlineCrossing.interface";
import { ValidationRunCountlineCrossing } from "../validationRunCountlineCrossing.interface";
import { ComputerVisionRunProgressUpdate } from "../computerVisionRunProgressUpdate.interface";
import { TriggerReason as TriggerReasonProto } from "../../enums/proto.enum";
import { SceneCaptureTriggerReason } from "../../vivacity/core/scene_capture_trigger_reasons_pb";
import { TriggerReason } from "../../enums";
import { VPZone } from "../vpZone.interface";
import { CVRunTurningMovement } from "../cvRunTurningMovement.interface";

export const nullToUndefined = <T>(maybeNull: T | null): T | undefined => {
  if (maybeNull === null) {
    return undefined;
  }
  return maybeNull;
};

export const undefinedToArray = <T>(maybeUndefined: T[] | undefined): T[] => {
  if (maybeUndefined === undefined) {
    return [];
  }
  return maybeUndefined;
};

export const videoHydrationToVideo = (hydration: VideoHydration, videoID: VideoID, vpid: VPID): Video => {
  // TODO - there's probably some missing fields from the DB which we want to add here
  return {
    id: videoID,
    requestId: hydration.request_id,
    vpid,
    bitrate: hydration.bitrate,
    buffers: hydration.buffers,
    computerVisionRuns: hydration.computer_vision_runs
      ? Object.keys(hydration.computer_vision_runs).map(id => parseInt(id, 10))
      : undefined,
    startAt: hydration.start_time ? new Date(hydration.start_time) : undefined,
    endAt: hydration.end_time ? new Date(hydration.end_time) : undefined,
    startFrame: nullToUndefined(hydration.start_frame),
    endFrame: nullToUndefined(hydration.end_frame),
    frameRateDenominator: hydration.frame_rate_denominator,
    frameRateNumerator: hydration.frame_rate_numerator,
    height: hydration.height,
    width: hydration.width,
    // TODO: fix the below - can either be base64 or json or string - check in order
    // meta: hydration.meta ? JSON.parse(hydration.meta) : undefined,
    recordingProgress: nullToUndefined(hydration.recording_progress),
    requestedBy: hydration.requested_by,
    triggerReason: hydration.trigger_reason,
    triggerType: hydration.trigger_type,
    status: hydration.status,
    thumbnailUrl: hydration.thumbnail_url,
    rawVideoPath: nullToUndefined(hydration.raw_video_path),
    totalBytes: nullToUndefined(hydration.total_bytes),
    updatedAt: hydration.updated_at ? new Date(hydration.updated_at) : undefined,
    updatedBy: nullToUndefined(hydration.updated_by),
    uploadingProgress: nullToUndefined(hydration.uploading_progress),
    bucketPath: nullToUndefined(hydration.bucket_path),
    downloadUrl: undefined,
    errors: undefined,
    meta: undefined,
    thumbnail: undefined,
    extraThumbnails: [],
    shouldRetain: hydration.should_retain,
  };
};

export const zoneHydrationToVPZone = (hydration: ZoneHydration, vpId: VPID): VPZone => {
  return {
    vpId,
    id: hydration.zone_id,
    geometry: geometryHydrationToPoints(hydration.geometry),
    isNearMiss: hydration.is_near_miss,
  };
};

// "((10781,16383),(11420,12693),(1950,6939),(0,8999),(0,16383),(10781,16383))"
const geometryHydrationToPoints = (string: string) => {
  const geometryString = string.substring(2, string.length - 2);
  const pointStrings = geometryString.split("),(");
  return pointStrings.map(pointString => {
    const [x, y] = pointString.split(",");
    return {
      x: parseInt(x, 10),
      y: parseInt(y, 10),
    };
  });
};

export const cvRunHydrationToCVRun = (
  hydration: CVRunHydration,
  cvRunID: CVRunID,
  videoID: VideoID,
  vpid: VPID,
  triggerReason: TriggerReason = TriggerReasonProto[SceneCaptureTriggerReason.UNKNOWN_TRIGGER_REASON]
): ComputerVisionRun => {
  // TODO - there's probably some missing fields from the DB which we want to add here
  return {
    countlineCrossings: new Map<CountlineID, Map<FrameNumber, CVRunCountlineCrossing>>(),
    turningMovements: new Map<TurningMovementStartZone, Map<TurningMovementEndZone, CVRunTurningMovement[]>>(),
    videoID: videoID,
    vpid: vpid,
    id: cvRunID,
    status: hydration.status,
    supermarioImage: hydration.supermario_image,
    processedVideoPath: hydration.processed_video_path,
    dtfsUrl: hydration.dtfs_url,
    validationRuns: hydration.validation_runs ? Object.keys(hydration.validation_runs).map(id => parseInt(id, 10)) : [],
    imageSpaceCountlines: nullToUndefined(hydration.image_space_countlines_json),
    imageSpaceMasks: nullToUndefined(hydration.image_space_masks_json),
    imageSpaceTurningZones: nullToUndefined(hydration.image_space_turning_zones_json),
    outputDrawingVideo: hydration.output_drawing_video,
    priority: hydration.priority,
    processingPercent: hydration.processing_percent,
    thumbnail: undefined,
    extraThumbnails: [],
    videoDownloadURL: undefined,
    skipValidation: hydration.skip_validation,
    createdAt: undefined,
    createdBy: undefined,
    lastReportedError: undefined,
    lastRunStderr: undefined,
    lastRunStdout: undefined,
    processorID: undefined,
    supermarioValues: undefined,
    isNearMiss: triggerReason === TriggerReasonProto[SceneCaptureTriggerReason.NEAR_MISS_VALIDATION],
    rawDtfsFromSensor: hydration.raw_dtfs_from_sensor,
  };
};

export const validationRunHydrationToValidationRun = (
  hydration: ValidationRunHydration,
  valRunID: ValidationRunID,
  cvRunID: CVRunID,
  videoID: VideoID,
  vpid: VPID
): ValidationRun => {
  // TODO - there's probably some missing fields from the DB which we want to add here
  return {
    cvRunID: cvRunID,
    videoID: videoID,
    vpid: vpid,
    id: valRunID,
    status: hydration.status,
    passed: nullToUndefined(hydration.passed),
    startedAt: hydration.started_at ? new Date(hydration.started_at) : undefined,
    startedByUserEmail: hydration.started_by_user_email,
    startedByUserId: hydration.started_by_user_id,
    completedAt: hydration.completed_at ? new Date(hydration.completed_at) : undefined,
    completedByUserEmail: nullToUndefined(hydration.completed_by_user_email),
    completedByUserId: nullToUndefined(hydration.completed_by_user_email),
    countlineValidationRuns: hydration.countline_validation_runs
      ? Object.keys(hydration.countline_validation_runs).map(countlineID => valRunID + "-" + countlineID)
      : [],
    deletedAt: hydration.deleted_at ? new Date(hydration.deleted_at) : undefined,
    thumbnail: undefined,
    extraThumbnails: [],
    videoDownloadURL: undefined,
    customValidatedClasses: hydration.custom_validated_classes || undefined,
  };
};

export const countlineValidationRunHydrationToCountlineValidationRun = (
  hydration: CountlineValidationRunHydration,
  valRunID: ValidationRunID,
  countlineID: CountlineID
): CountlineValidationRun => {
  // TODO - there's probably some missing fields from the DB which we want to add here
  return {
    validationRunCountlineCrossings: new Map<FrameNumber, ValidationRunCountlineCrossing>(),
    id: `${valRunID}-${countlineID}`,
    completedByUserEmail: nullToUndefined(hydration.completed_by_user_email),
    completedByUserId: nullToUndefined(hydration.completed_by_user_id),
    notes: undefinedToArray(nullToUndefined(hydration.notes)),
    passed: nullToUndefined(hydration.passed),
    startedByUserEmail: nullToUndefined(hydration.started_by_user_email),
    startedByUserId: nullToUndefined(hydration.started_by_user_id),
    status: hydration.status,
  };
};

export const CVRunCountlineCrossingsCountlineHydrationToComputerVisionRunCountlineCrossings = (
  hydration: CVRunCountlineCrossingsCountlineHydration
): ComputerVisionRunCountlineCrossings => {
  const minAvailableFrameNumberByCountline: { [countlineId: CountlineID]: number } = {};

  const out = new Map<CountlineID, Map<FrameNumber, CVRunCountlineCrossing>>();

  Object.entries(hydration).forEach(
    ([hydrationCountlineIdString, hydrationCrossings]: [CVRunIDStr, CVRunCountlineCrossingHydration[]]) => {
      const countlineId: CountlineID = parseInt(hydrationCountlineIdString, 10);
      out.set(countlineId, new Map<FrameNumber, CVRunCountlineCrossing>());
      minAvailableFrameNumberByCountline[countlineId] = 0;

      hydrationCrossings.sort((a, b) => a.frame_number - b.frame_number);

      hydrationCrossings.forEach((hydrationCrossing: CVRunCountlineCrossingHydration, i) => {
        const actualFrameNumber: FrameNumber = hydrationCrossing.frame_number;
        let frameNumber = actualFrameNumber;
        if (actualFrameNumber < minAvailableFrameNumberByCountline[countlineId]) {
          frameNumber = minAvailableFrameNumberByCountline[countlineId];
        }
        minAvailableFrameNumberByCountline[countlineId] = frameNumber + 1;

        const firstTimestampMicrosecondsEnteredZoneByZoneId: { [zoneId: number]: number } | null =
          hydrationCrossing.first_timestamp_microseconds_entered_zone_by_zone_id
            ? Object.entries(hydrationCrossing.first_timestamp_microseconds_entered_zone_by_zone_id).reduce(
                (agg, [zoneId, microseconds]) => {
                  agg[parseInt(zoneId, 10)] = microseconds;
                  return agg;
                },
                {}
              )
            : {};

        const lastTimestampMicrosecondsLeftZoneByZoneId: { [zoneId: number]: number } | null =
          hydrationCrossing.last_timestamp_microseconds_left_zone_by_zone_id
            ? Object.entries(hydrationCrossing.last_timestamp_microseconds_left_zone_by_zone_id).reduce(
                (agg, [zoneId, microseconds]) => {
                  agg[parseInt(zoneId, 10)] = microseconds;
                  return agg;
                },
                {}
              )
            : {};

        const crossing: CVRunCountlineCrossing = {
          crossingTime: new Date(hydrationCrossing.crossing_time),
          frameNumber,
          actualFrameNumber,
          trackClass: hydrationCrossing.track_class,
          clockwise: hydrationCrossing.clockwise,
          crossingPoint: [hydrationCrossing.crossing_point.x, hydrationCrossing.crossing_point.y],
          trackNumber: hydrationCrossing.track_number,
          longTermAverageSpeed: nullToUndefined(hydrationCrossing.long_term_average_speed_cm_s),
          trackAverageSpeed: nullToUndefined(hydrationCrossing.track_average_speed_cm_s),
          anprVehicleClass: nullToUndefined(hydrationCrossing.anpr_vehicle_class),
          anprAssociatedTrackNumber: nullToUndefined(hydrationCrossing.anpr_associated_track_number),
          topRankedPlate: nullToUndefined(hydrationCrossing.top_ranked_plate),
          topRankedConfidence: nullToUndefined(hydrationCrossing.top_ranked_confidence),
          otherPlates: undefinedToArray(nullToUndefined(hydrationCrossing.other_plates)),
          otherConfidences: undefinedToArray(nullToUndefined(hydrationCrossing.other_confidences)),
          cumulativeStoppedMicroseconds: hydrationCrossing.cumulative_stopped_microseconds,
          cumulativeTotalMicroseconds: hydrationCrossing.cumulative_total_microseconds,
          firstTimestampMicrosecondsEnteredZoneByZoneId,
          lastTimestampMicrosecondsLeftZoneByZoneId,
          trackEndTimestampMicroseconds: hydrationCrossing.track_end_timestamp_microseconds,
        };
        const crossingsByFrameNumber = out.get(countlineId)!;
        crossingsByFrameNumber.set(frameNumber, crossing);
        out.set(countlineId, crossingsByFrameNumber);
      });
    }
  );

  return out;
};

export const CVRunTurningMovementStartZoneToComputerVisionRunTurningMovements = (
  hydration: CVRunTurningMovementsStartZoneHydration
): ComputerVisionRunTurningMovements => {
  const out = new Map<TurningMovementStartZone, Map<TurningMovementEndZone, CVRunTurningMovement[]>>();
  Object.entries(hydration).forEach(
    ([hydrationTurningMovementStartZoneString, hydrationTurningMovements]: [
      TurningMovementStartZoneStr,
      CVRunTurningMovementsHydration[]
    ]) => {
      const startZoneId: TurningMovementStartZone = parseInt(hydrationTurningMovementStartZoneString, 10);
      out.set(startZoneId, new Map<TurningMovementEndZone, CVRunTurningMovement[]>());

      hydrationTurningMovements.sort((a, b) => a.end_zone_id - b.end_zone_id);

      hydrationTurningMovements.forEach(hydrationMovement => {
        const turningMovement: CVRunTurningMovement = {
          intermediateZoneIds: hydrationMovement.intermediate_zone_ids,
          startTimestampMicroseconds: new Date(hydrationMovement.start_timestamp_microseconds),
          endTimestampMicroseconds: new Date(hydrationMovement.end_timestamp_microseconds),
          trackClass: hydrationMovement.track_class,
          trackNumber: hydrationMovement.track_number,
        };
        const turningMovementsByEndZone = out.get(startZoneId)!;
        if (turningMovementsByEndZone.has(hydrationMovement.end_zone_id)) {
          const arrayOfTurns = turningMovementsByEndZone.get(hydrationMovement.end_zone_id)!;
          arrayOfTurns.push(turningMovement);
          turningMovementsByEndZone.set(hydrationMovement.end_zone_id, arrayOfTurns);
          out.set(startZoneId, turningMovementsByEndZone);
        } else {
          turningMovementsByEndZone.set(hydrationMovement.end_zone_id, [turningMovement]);
          out.set(startZoneId, turningMovementsByEndZone);
        }
      });
    }
  );
  return out;
};

export const validationCrossingHydrationtoValidationCrossings = (
  hydration: ValidationCrossingsHydration
): Map<FrameNumber, ValidationRunCountlineCrossing> => {
  const result = new Map<FrameNumber, ValidationRunCountlineCrossing>();

  Object.entries(hydration).forEach(
    ([hydrationFrameNumberString, hydrationCrossing]: [FrameNumberStr, ValidationCrossingHydration]) => {
      const frameNumber: FrameNumber = parseInt(hydrationFrameNumberString, 10);
      const crossing: ValidationRunCountlineCrossing = {
        actualFrameNumber: frameNumber,
        clockwise: hydrationCrossing.clockwise,
        createdByUserEmail: hydrationCrossing.created_by_user_email,
        createdByUserId: hydrationCrossing.created_by_user_id,
        crossingTime: hydrationCrossing.crossing_time,
        detectionClassV2Id: hydrationCrossing.detection_class_v2_id,
        frameNumber: frameNumber,
        plate: hydrationCrossing.plate ?? "",
        anprVehicleClass: nullToUndefined(hydrationCrossing.anpr_vehicle_class),
        saved: true,
      };
      result.set(frameNumber, crossing);
    }
  );
  return result;
};

export const computerVisionRunProgressUpdateHydrationToComputerVisionRunProgressUpdate = (
  hydration: ComputerVisionRunProgressUpdateHydration
): ComputerVisionRunProgressUpdate => {
  return {
    id: hydration.computer_vision_run_id,
    status: hydration.status,
    processingPercent: hydration.processing_percent,
    errorString: hydration.error_string || "",
    processorId: hydration.processor_id,
  };
};
