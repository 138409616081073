import React, { FC, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { toJS } from "mobx";
import { Link } from "react-router-dom";
import { RoutePath, ValidationMode } from "../../enums";
import { FreneticityButton } from "../styledElements";
import { URL_QUERY_PARAMS } from "../../stores/url.store";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { ComputerVisionRun, ValidationRun, Video } from "../../interfaces";
import { DateTime } from "luxon";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { useStores } from "../../hooks/useStores.hook";
import { NEW_CV_RUN_EVENT, NewCVRunEvent } from "../../stores/cvRun.store";
import LinearProgress from "@mui/material/LinearProgress";
import _ from "lodash";
import {
  FETCH_FIRST_VIDEO_THUMBNAIL_EVENT,
  FETCH_VIDEO_SIGNED_URL,
  FETCH_VIDEO_THUMBNAILS_EVENT,
  FetchVideoThumbnailsEvent,
} from "../../stores/video.store";
import { TriggerReason } from "../../enums/proto.enum";
import { SceneCaptureTriggerReason } from "../../vivacity/core/scene_capture_trigger_reasons_pb";
import { CopyVideoLinksButton } from "./CopyVideoLinksButton";
import { NewCVRunModal } from "../modals/NewCVRunModal";

const idColumn: GridColDef<TableVideo, number> = {
  field: "id",
  headerName: "Video ID",
  flex: 0.1,
  valueFormatter: v => v.value,
};

const vpidColumn: GridColDef<TableVideo> = {
  field: "vpid",
  headerName: "VPID",
  flex: 0.1,
  renderCell: ({ row }) => (row.vpid ? row.vpid : ""),
};

const startTimeColumn: GridColDef<TableVideo> = {
  field: "startAt",
  headerName: "Start Time",
  flex: 0.35,
  renderCell: ({ row }) => (row.startAt ? row.startAt.toUTCString() : ""),
};

const endTimeColumn: GridColDef<TableVideo> = {
  field: "endAt",
  headerName: "End Time",
  flex: 0.35,
  renderCell: ({ row }) => (row.endAt ? row.endAt.toUTCString() : ""),
};

const statusColumn: GridColDef<TableVideo> = {
  field: "status",
  headerName: "Status",
  flex: 0.2,
  renderCell: ({ row }) => {
    if (row.status === "UPLOADING") {
      return (
        <div style={{ width: "100%" }}>
          Uploading {((row.uploadingProgress ?? 0) * 100).toFixed(1)}%
          <LinearProgress variant="determinate" value={(row.uploadingProgress ?? 0) * 100} />
        </div>
      );
    } else if (row.status === "RECORDING" || row.status === "UPLOAD_CREATED") {
      return (
        <div style={{ width: "100%" }}>
          {_.upperFirst(_.lowerCase(row.status))} {((row.recordingProgress ?? 0) * 100).toFixed(1)}%
          <LinearProgress color="error" variant="determinate" value={(row.recordingProgress ?? 0) * 100} />
        </div>
      );
    }
    return _.upperFirst(_.lowerCase(row.status));
  },
};

const durationColumn: GridColDef<TableVideo> = {
  field: "duration",
  headerName: "Duration",
  flex: 0.1,
  renderCell: ({ row }) => {
    if (row.startAt && row.endAt) {
      return DateTime.fromJSDate(row.endAt).diff(DateTime.fromJSDate(row.startAt)).toFormat("m'm' ss's'");
    }
    return "";
  },
};

export const ThumbnailButton: FC<{ row: Video | ComputerVisionRun | ValidationRun }> = ({ row }) => {
  const [index, setIndex] = useState(0);
  const interval = useRef<number | null>(null);

  const fetchVideoThumbsEvent: FetchVideoThumbnailsEvent = {
    videoID: "videoID" in row ? row.videoID : row.id,
  };

  let signedURL;
  if ("videoDownloadURL" in row) {
    signedURL = row.videoDownloadURL;
  }
  if ("downloadUrl" in row) {
    signedURL = row.downloadUrl;
  }

  useEffect(() => {
    const fetchThumbEvent = new CustomEvent(FETCH_FIRST_VIDEO_THUMBNAIL_EVENT, { detail: fetchVideoThumbsEvent });
    window.dispatchEvent(fetchThumbEvent);
    const fetchSignedVideoEvent = new CustomEvent(FETCH_VIDEO_SIGNED_URL, { detail: fetchVideoThumbsEvent });
    window.dispatchEvent(fetchSignedVideoEvent);
  }, []);

  const event = new CustomEvent(FETCH_VIDEO_THUMBNAILS_EVENT, { detail: fetchVideoThumbsEvent });

  const onMouseOver = () => {
    if (row.extraThumbnails.length) {
      if (interval.current === null) {
        interval.current = window.setInterval(() => {
          if (row.extraThumbnails.length) {
            setIndex(prev => (prev + 1) % row.extraThumbnails.length);
          }
        }, 200);
      }
    } else {
      window.dispatchEvent(event);
      row.extraThumbnails.push({ thumbnail: row.thumbnail ?? "", generation: 100000000000000000000000000000000 });
    }
  };

  const onMouseOut = () => {
    if (interval.current !== null) {
      window.clearInterval(interval.current);
      interval.current = null;
    }
  };
  return (
    <IconButton>
      <Box
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          height: "50px",
          width: "89px",
          backgroundImage: row.extraThumbnails.length
            ? `url(${row.extraThumbnails[index].thumbnail}),` +
              row.extraThumbnails.map(thumb => `url(${thumb.thumbnail})`).join(",") // Append these to prefetch blob URLs
            : `url(${row.thumbnail})`,
          backgroundSize: "89px",
        }}
      >
        {signedURL ? (
          <OndemandVideoIcon
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            sx={{ opacity: 0.3 }}
            color={"action"}
            fontSize={"large"}
          />
        ) : null}
      </Box>
    </IconButton>
  );
};

const actionsColumn: (
  setIsNewCVRunModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setNewCVRunVideoId: React.Dispatch<React.SetStateAction<number>>
) => GridColDef<TableVideo> = (setIsNewCVRunModalOpen, setNewCVRunVideoId) => {
  return {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: ({ row }) => {
      const params = new URLSearchParams(location.search);
      params.set(URL_QUERY_PARAMS.SELECTED_VIDEO, row.id.toString(10));
      if (row.vpid) {
        params.set(URL_QUERY_PARAMS.SELECTED_VISION_PROGRAM, row.vpid.toString(10));
      }

      const handleNewCVRun = () => {
        if (row.status !== "UPLOADING_COMPLETE") {
          console.error(`Error: Unable to start new CV run for ${row.id} with status: ${row.status}`);
          return;
        }
        setNewCVRunVideoId(row.id);
        setIsNewCVRunModalOpen(true);
      };

      return (
        <Grid container alignItems={"center"} justifyContent={"start"}>
          <Grid item>
            <Tooltip enterDelay={2000} title="Watch Video" arrow>
              {row.status === "UPLOADING_COMPLETE" && row.downloadUrl ? (
                <Link
                  to={{
                    pathname: RoutePath.Player,
                    search: params.toString(),
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <ThumbnailButton row={row} />
                </Link>
              ) : (
                <ThumbnailButton row={row} />
              )}
            </Tooltip>
          </Grid>
          <Grid container item flexBasis={"80%"} spacing={1}>
            {row.childCVRunCount > 0 && (
              <Grid item>
                <Link
                  to={{
                    pathname: RoutePath.ComputerVisionRuns,
                    search: params.toString(),
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <FreneticityButton variant="outlined">CV Runs</FreneticityButton>
                </Link>
              </Grid>
            )}
            {row.childValidationRunCount > 0 && (
              <Grid item>
                <Link
                  to={{
                    pathname: RoutePath.ValidationRuns,
                    search: params.toString(),
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <FreneticityButton variant="outlined">Validation Runs</FreneticityButton>
                </Link>
              </Grid>
            )}
            <Grid item>
              <a
                href={`https://centricity.vivacitylabs.com/visionProgram/${row.vpid}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <FreneticityButton variant="outlined">CM</FreneticityButton>
              </a>
            </Grid>
            <Grid item>
              <FreneticityButton onClick={handleNewCVRun} disabled={row.status !== "UPLOADING_COMPLETE"}>
                New CV Run
              </FreneticityButton>
            </Grid>
          </Grid>
        </Grid>
      );
    },
  };
};

interface VideoChildCounts {
  childCVRunCount: number;
  childValidationRunCount: number;
}

type TableVideo = Video & VideoChildCounts;

export const VideoTable = observer(() => {
  const { entitiesStore, urlStore } = useStores();
  const [isNewCVRunModalOpen, setIsNewCVRunModalOpen] = useState(false);
  const [newCVRunVideoId, setNewCVRunVideoId] = useState(0);

  const { selectedValidationMode, search } = urlStore;

  const isNearMissMode = selectedValidationMode === ValidationMode.NearMissValidation;

  // Force a re-render of the whole table when thumbnails update, because cell renderers aren't observers
  entitiesStore.videos.forEach(video => {
    const dummyThumbnailRead = video.thumbnail;
    const dummyThumbnailsRead = video.extraThumbnails.map(thumb => {
      return thumb.thumbnail + video.status + video.uploadingProgress ?? "" + video.recordingProgress ?? "";
    });
  });

  const filteredVideos = entitiesStore.filteredVideos.filter(video =>
    video.triggerReason === TriggerReason[SceneCaptureTriggerReason.NEAR_MISS_VALIDATION]
      ? isNearMissMode
      : !isNearMissMode
  );

  const tableInfo: TableVideo[] = filteredVideos.map(video => {
    const childCVRunCount = entitiesStore.getComputerVisionRunsForVideoID(video.id).length;
    const childValidationRunCount = entitiesStore.getValidationRunsForVideoID(video.id).length;
    return {
      ...video,
      childCVRunCount,
      childValidationRunCount,
    };
  });

  return (
    <>
      {isNearMissMode && <CopyVideoLinksButton filteredVideos={filteredVideos} search={search} />}

      <DataGrid
        rows={toJS(tableInfo)}
        columns={[
          idColumn,
          vpidColumn,
          statusColumn,
          startTimeColumn,
          endTimeColumn,
          durationColumn,
          actionsColumn(setIsNewCVRunModalOpen, setNewCVRunVideoId),
        ]}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        getRowHeight={() => "auto"}
      />
      <NewCVRunModal
        isOpen={isNewCVRunModalOpen}
        onClose={() => setIsNewCVRunModalOpen(false)}
        videoId={newCVRunVideoId}
      />
    </>
  );
});
